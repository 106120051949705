import { useCallback, useContext, useEffect, useState } from 'react';

import { Layout, Layouts } from 'react-grid-layout';

import { UserDashboard, WidgetItem } from '@vyce/core/src/types';
import { DeviceContext, TimeDashboardModuleContext, TimeModuleContext } from '@vyce/core/src/contexts';

export const useTimeDashboardData = () => {
  const [timeDashboard, setTimeDashboard] = useState<UserDashboard>();

  const { isMobile } = useContext(DeviceContext);
  const { dashboards, name, selectedCompanyId, permissions, updateUserDashboards } =
    useContext(TimeDashboardModuleContext);
  const { locations } = useContext(TimeModuleContext);

  const onWidgetStatusChange = useCallback(
    (systemName: string) => {
      const dashboardWidgets = timeDashboard?.widgets || [];
      if (dashboardWidgets.length === 0) return;
      const newWidgets: WidgetItem[] = dashboardWidgets.map(item => {
        return item.system_name === systemName ? { ...item, is_active: !item.is_active } : item;
      });

      setTimeDashboard(value => ({ ...value, widgets: newWidgets }));
    },
    [timeDashboard?.widgets]
  );

  const updateDashboards = (newWidgetsSettings?: UserDashboard) => {
    if (!timeDashboard) {
      return;
    }
    updateUserDashboards({
      ...dashboards,
      time: newWidgetsSettings ?? timeDashboard,
    });
  };

  const resetDashboards = () => {
    if (timeDashboard && timeDashboard.widgets) {
      const restoredDashboard = timeDashboard.widgets.map(item => {
        return { ...item, is_active: true };
      });
      setTimeDashboard(value => ({ ...value, widgets: restoredDashboard }));
      updateUserDashboards({
        ...dashboards,
        time: { ...timeDashboard, widgets: restoredDashboard },
      });
    }
  };

  const onLayoutChange = (currentLayout: Layout[], newLayouts: Layouts) => {
    const newWidgetsSettings = { ...timeDashboard, layouts: newLayouts };
    setTimeDashboard(newWidgetsSettings);
    updateDashboards(newWidgetsSettings);
  };

  useEffect(() => {
    if (dashboards?.time) {
      setTimeDashboard(dashboards.time);
    }
  }, [dashboards?.time]);

  return {
    isMobile,
    name,
    timeDashboard,
    permissions,
    selectedCompanyId,
    locations,
    onWidgetStatusChange,
    onLayoutChange,
    updateDashboards,
    resetDashboards,
  };
};
